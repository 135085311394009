import EgoForm from '@egodesign/form'
import EgoModal from '@egodesign/modal'
import { redirectToThankYouPage } from '../modules/tools'
import { contactFormGTM } from '../modules/marketing/gtm'
import { metaLead } from '../modules/marketing/meta-api'
import { getCSRFTokenValue, validateRecaptcha } from '../modules/form-helpers'

export const formContact = () => {
    const formContactElement = document.querySelector('#contactForm') // form
    if (!formContactElement) return

    const complaints = formContactElement.querySelector('#complaintsCheckbox') // check Quejas y Reclamos

    const fields = [
        // divs de los inputs que no estan en Quejas y Reclamos
        document.getElementById('contactCompanyDiv'),
        document.getElementById('contactCategoryDiv'),
        document.getElementById('contactEmployeesDiv'),
        document.getElementById('contactProvinceDiv'),
        // document.getElementById("contactCountryDiv"),
    ]

    // const statesSelect = document.getElementById("contactProvinceInput");
    // const countriesSelect = document.getElementById("contactCountryInput");

    const control = [
        // inputs que no estan en Quejas y Reclamos
        document.getElementById('contactCompanyInput'),
        document.getElementById('contactCategoryInput'),
        document.getElementById('contactEmployeesInput'),
        // statesSelect,
        // countriesSelect,
    ]

    const rsDiv = formContactElement.querySelector('#contactRSDiv') // div de Razon Social
    const rsInput = formContactElement.querySelector('#contactRSInput') // input de Razon Social
    const productsCheckboxDiv = formContactElement.querySelectorAll('.contactCheckProduct') // divs de los checkbox de Productos
    const productsCheckbox = formContactElement.querySelectorAll('.contactCheckProduct input') // checkbox de Productos

    const actionsCheckbox = formContactElement.querySelectorAll('.contactCheckAction input') // checkbox de Me interesa (Acciones)

    const selectLineType = formContactElement.querySelector('#contactLineInput')

    const categoriesGroup = formContactElement.querySelector('.--checkboxGroup.--categories')

    // FORMULARIO
    let modalError, retrySubmitBtn, countdownInterval

    const modalErrorNode = document.querySelector('#modal-form-contact-error')

    if (modalErrorNode) {
        modalError = new EgoModal({
            element: modalErrorNode,
            onClose: () => clearInterval(countdownInterval),
        })
        retrySubmitBtn = modalErrorNode?.querySelector('.btn-retry-submit')
        const btnSubmit = formContactElement.querySelector("button[type='submit']")
        retrySubmitBtn.addEventListener('click', () => {
            modalError.close()
            setTimeout(() => {
                btnSubmit.click() // Vuelve a mandar la solicitud
            }, 1000)
        })
        modalErrorNode.querySelector('.modal-close')?.addEventListener('click', () => {
            modalError.close()
        })
    }

    // Coundown Node
    const counterElement = modalErrorNode?.querySelector('.modal-counter-sec')

    // Definimos la función para actualizar el contador
    function updateCounter(seconds) {
        if (seconds === 1) {
            counterElement.textContent = `${seconds} segundo` // Actualizamos el contenido del elemento
        } else {
            counterElement.textContent = `${seconds} segundos` // Actualizamos el contenido del elemento
        }
    }

    const csrf = getCSRFTokenValue(formContactElement)

    const contactForm = new EgoForm({
        element: formContactElement,
        submitType: 'fetch',
        requestHeaders: {
            'X-CSRFToken': csrf,
        },
        resetOnSuccess: false,
        resetLoaderOnSuccess: false,
        customValidationMessages: {
            default: {
                empty: 'Campo requerido.',
                invalid: 'Campo nó válido.',
                minLength: 'Min. [[var]] caracteres.',
                maxLength: 'Máx. [[var]] caracteres.',
            },
            categories: {
                empty: 'Seleccioná al menos una opción',
            },
            actions: {
                empty: 'Seleccioná al menos una opción',
            },
            cuit: {
                empty: 'Campo requerido.',
                invalid: 'Revisá este CUIT',
            },
            cuit_cuil: {
                empty: 'Campo requerido.',
                invalid: 'Revisá este CUIT',
            },
        },
        customValidations: {
            phone_sum: [
                {
                    name: 'phone_sum',
                    condition: (value) => {
                        const codeAreaVal = formContactElement.querySelector('#contactAreaInput')?.value
                        return value.length + codeAreaVal.length === 10
                    },
                    message: 'Revisá este número y el código de área',
                },
            ],
            cuit_cuil: [
                {
                    name: 'cuit_cuil',
                    condition: (cuit) => {
                        if (cuit.length < 11 || cuit.length > 13) return false;
                        let rv = false;
                        let resultado = 0;
                        let cuit_nro = cuit.replace("-", "");
                        const codes = "6789456789";
                        let verificador = parseInt(cuit_nro[cuit_nro.length - 1]);
                        let x = 0;

                        while (x < 10) {
                            let digitoValidador = parseInt(codes.substring(x, x + 1));
                            if (isNaN(digitoValidador)) digitoValidador = 0;
                            let digito = parseInt(cuit_nro.substring(x, x + 1));
                            if (isNaN(digito)) digito = 0;
                            let digitoValidacion = digitoValidador * digito;
                            resultado += digitoValidacion;
                            x++;
                        }
                        resultado = resultado % 11;
                        rv = (resultado == verificador);
                        return rv;
                    },
                    message: 'Revisá este CUIT',
                },
            ],
        },
        onValidationError: (errorFieldsNames) => {
            if (errorFieldsNames.length) contactFormGTM({ element: formContactElement, validationError: errorFieldsNames })
        },
        onError: () => {
            // Deshabilitamos el botón de reintentar
            retrySubmitBtn.classList.add('--disabled')

            // Iniciamos el contador
            counterElement.parentElement.style.display = 'unset'
            let secondsLeft = 5
            updateCounter(secondsLeft)

            // Creamos un intervalo que se ejecutará cada segundo
            countdownInterval = setInterval(() => {
                secondsLeft-- // Restamos un segundo
                updateCounter(secondsLeft) // Actualizamos el contador

                if (secondsLeft === 0) {
                    clearInterval(countdownInterval) // Detenemos el intervalo cuando llegamos a cero
                    // Ocultamos el countdown
                    counterElement.parentElement.style.display = 'none'
                    // Habilitamos en botón de reintentar
                    retrySubmitBtn.classList.remove('--disabled')
                }
            }, 1000)

            // Abrimos el modal si aún no está abierto
            if (!modalErrorNode.classList.contains('--open')) modalError.open()

            contactFormGTM({ element: formContactElement })
        },
        onSuccess: (res) => {
            res.json().then(({ data }) => {
                contactFormGTM({ element: formContactElement, data })
                metaLead(data)
            })

            redirectToThankYouPage()
        },
        onBeforeSubmit: () => {
            validateRecaptcha(contactForm, csrf)
        },
        preventSubmit: true,
    })
    // ------------------

    // Me interesa
    if (fields && actionsCheckbox) {
        // let timesInputBuyIsChecked = 0;

        const setComplaintsOn = () => {
            complaints.checked = true // marco Quejas y Reclamos
            formContactElement.querySelector('input[type="hidden"][name="type"]').value = 'complaint'

            actionsCheckbox.forEach((check) => {
                // desmarco todos los otros checkbox de Me interesa
                check.checked = false
                check.parentElement.classList.remove('--hidden')
            })
            fields.forEach((field) => {
                // oculto los campos que no estan en Quejas y Reclamos
                field.classList.remove('--required')
                field.classList.add('--hidden')
            })
            control.forEach((input) => {
                // vacio los campos que no estan en Quejas y Reclamos
                input.value = ''
            })
            productsCheckboxDiv.forEach((checkbox) => {
                // oculto los checkbox de Productos
                checkbox.classList.add('--hidden')
            })
            productsCheckbox.forEach((product) => {
                // desmarco todos los checkbox de Productos
                product.checked = false
            })
            rsDiv.classList.remove('--hidden') // muestro el campo de Razon Social

            // Quita requerido de los checkbox de los Productos
            // !TODO: Check why not working add or removing class --required
            // categoriesGroup.classList.remove('--required')
            categoriesGroup.removeAttribute('data-type')
        }

        const setComplaintsOff = () => {
            complaints.checked = false // desmarco Quejas y Reclamos
            formContactElement.querySelector('input[type="hidden"][name="type"]').value = 'contact'

            fields.forEach((field) => {
                // muestro los campos que no estan en Quejas y Reclamos
                field.classList.add('--required')
                field.classList.remove('--hidden')
            })
            productsCheckboxDiv.forEach((checkbox) => {
                // muestro los checkbox de Productos
                checkbox.classList.remove('--hidden')
            })
            rsDiv.classList.add('--hidden')
            rsInput.value = '' // oculto y vacía el campo de Razon Social

            // Agrega requerido de los checkbox de los Productos
            // !TODO: Check why not working add or removing class --required
            // categoriesGroup.classList.add('--required')
            categoriesGroup.setAttribute("data-type", "checkbox");

        }

        //! TODO: Check method if is necessary
        // const setBuyNewOrUsed = (target) => {
        //     if(target.value === "Comprar nuevo" || target.value === "Comprar usado") {

        //         if(!target.checked && timesInputBuyIsChecked === 0) {
        //             return
        //         }

        //         const inputCUIT = formContactElement.querySelector('[data-type="cuit"]')
        //         if(target.checked) {
        //             if(timesInputBuyIsChecked === 0) {
        //                 inputCUIT.classList.remove('--required')
        //                 inputCUIT.classList.add('--hidden')
        //             }
        //             timesInputBuyIsChecked++
        //             return
        //         }

        //         timesInputBuyIsChecked--
        //         if(timesInputBuyIsChecked <= 0) {
        //             inputCUIT.classList.add('--required')
        //             inputCUIT.classList.remove('--hidden')
        //             timesInputBuyIsChecked = 0
        //         }
        //     }

        // }

        if (complaints) {
            complaints.addEventListener('change', (e) => {
                if (e.target.checked === true) {
                    setComplaintsOn()
                }
                if (e.target.checked === false) {
                    setComplaintsOff()
                }
            })

            if (complaints.checked) {
                setComplaintsOn()
            }
        }

        actionsCheckbox.forEach((check) => {
            check?.addEventListener('change', (e) => {
                //! TODO: Check method if is necessary
                // setBuyNewOrUsed(check)

                if (complaints && e.target.checked === true) {

                    complaints.checked = false
                    setComplaintsOff()
                }
            })
        })

        productsCheckbox.forEach((productCheckbox) => {
            const handlerChecks = () => {
                const productId = productCheckbox.getAttribute('data-id')

                // Iterar sobre los checkboxes de Actions
                actionsCheckbox.forEach((actionCheckbox) => {
                    const productsToShow = actionCheckbox.getAttribute('data-products')?.trim()

                    // Mostrar u ocultar el checkbox Actions según corresponda
                    if (!productsToShow.includes(productId)) {
                        if (productCheckbox.checked) {
                            actionCheckbox.parentElement.classList.add('--hidden')
                            actionCheckbox.checked = false
                            //! TODO: Check method if is necessary
                            // setBuyNewOrUsed(actionCheckbox)

                        } else {
                            actionCheckbox.parentElement.classList.remove('--hidden')
                        }
                    }
                })
            }
            handlerChecks()
            productCheckbox.addEventListener('change', () => {
                handlerChecks()
            })
        })
    }

    // muestro o mensaje de alerta en el campo de Telefono
    if (selectLineType) {
        selectLineType.addEventListener('change', (e) => {
            // limpia número de teléfono
            const phoneNumberInput = formContactElement.querySelector('#contactPhoneInput')
            phoneNumberInput.value = ''

            const alert = formContactElement.querySelector('#contactPhoneInputAlert')
            const internField = formContactElement.querySelector('#contactInternInput')

            if (e.target.value == 'movil') {
                alert.style.display = 'block'
                internField.disabled = true
                internField.value = ''
                internField.parentElement.classList.remove('--filled')
            } else {
                alert.style.display = 'none'
                internField.disabled = false
            }
        })
    }

    document.querySelectorAll('.no-leading-zero')?.forEach((input) => {
        input.addEventListener('input', (e) => {
            if (e.target.value?.toString().charAt(0) === '0') {
                const alert = input.parentElement.querySelector('.form__alert')
                if (alert) {
                    alert.classList.remove('--hidden')
                }
            }
            input.value = input.value.replace(/\b0+/g, '')
        })
        input.addEventListener('paste', (e) => {
            if (e.target[0] == 0) {
                const alert = input.parentElement.querySelector('.form__alert')
                if (alert) {
                    alert.classList.remove('--hide')
                }
            }
            input.value = input.value.replace(/\b0+/g, '')
        })
    })

    document.querySelectorAll('.no-leading-15')?.forEach((input) => {
        input.addEventListener('input', (e) => {
            if (selectLineType?.value === 'movil') {
                const val = e.target.value.toString()
                if (val.length > 1 && val.charAt(0) === '1' && val.charAt(1) === '5') {
                    const alert = input.parentElement.querySelector('.form__alert')
                    if (alert) {
                        alert.classList.remove('--hidden')
                    }
                }
                input.value = input.value.replace(/\b15+/g, '')
            }
        })
        input.addEventListener('paste', (e) => {
            if (selectLineType?.value === 'movil') {
                const val = e.target.value.toString()
                if (val.length > 1 && val.charAt(0) === '1' && val.charAt(1) === '5') {
                    const alert = input.parentElement.querySelector('.form__alert')
                    if (alert) {
                        alert.classList.remove('--hidden')
                    }
                }
                input.value = input.value.replace(/\b15+/g, '')
            }
        })
    })
}
